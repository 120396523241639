//import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  //const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-white shadow-md sticky top-0 z-50">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="flex justify-between items-center py-4">
      {/* Logo */}
      <h1 className="text-3xl font-light bg-gradient-to-r from-indigo-400 to-pink-600 bg-clip-text text-transparent">
        <Link to="/">SCV Byte</Link>
      </h1>

      {/* Contact Information */}
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-start items-end space-y-4 sm:space-y-0 sm:space-x-6">
  <div className="flex items-center space-x-2">
    <i className="bi bi-envelope-fill text-gray-500"></i>
    <a href="mailto:admin@scvbyte.com" className="text-gray-700 hover:text-blue-500 text-sm font-medium">
      admin@scvbyte.com
    </a>
  </div>
  <div className="flex items-center space-x-2">
    <i className="bi bi-telephone-fill text-gray-500"></i>
    <span className="text-gray-700 text-sm font-medium">+6011-23746349</span>
  </div>
</div>

    </div>
  </div>
</header>
  );
};

export default Header;
