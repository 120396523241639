import { FaReact, FaNodeJs, FaPhp, FaJsSquare } from "react-icons/fa";
//import { SiTailwindcss } from "react-icons/si";

function Footer() {
  return (
    <footer className="bg-gray-100 py-6">
      <div className="max-w-7xl mx-auto text-center text-gray-500 text-sm">
        © 2022-2024 SCV Byte. All rights reserved.
        
        <div className="py-2">
              <div className="flex justify-center items-center space-x-4">
                {/* JavaScript Icon */}
                <div className="flex flex-col items-center">
                  <FaJsSquare className="text-gray-400 text-2xl" />
                </div>

                {/* Node.js Icon */}
                <div className="flex flex-col items-center">
                  <FaNodeJs className="text-gray-400 text-2xl" />
                </div>
                {/* React Icon */}
                <div className="flex flex-col items-center">
                  <FaReact className="text-gray-400 text-2xl" />
                </div>

                {/* Tailwind Icon 
                <div className="flex flex-col items-center">
                  <SiTailwindcss className="text-gray-400 text-2xl" />
                </div> */}

                {/* PHP Icon */}
                <div className="flex flex-col items-center">
                  <FaPhp className="text-gray-400 text-2xl" />
                </div>

                
              </div>
            </div>
      </div>
    </footer>
  );
}

export default Footer;
