function Page2() {
  return (
    <main className="bg-gray-50 min-h-screen flex items-center justify-center">
      <h1 className="text-4xl font-bold text-gray-700">
        Welcome to Page 2
      </h1>
    </main>
  );
}

export default Page2;
