import React, { useState, useRef } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import Spinner from "../components/Spinner";
import Toast from "../components/Toast";

function Home() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const formRef = useRef(null);

  const handleScrollToForm = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleOpen = () => {
    console.log("Opening modal");
    setIsOpen(true); // Open the modal
  };

  const handleClose = () => {
    console.log("Close button clicked");
    setIsOpen(false); // Close the modal
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    package: "",
    about: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleBlur = () => {
    setIsTouched(true); // Mark the field as "touched" when it loses focus
  };

  const isValidEmail =
    formData.email === "" || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Show spinner

    // Set a timeout to automatically hide the spinner after 10 seconds
    const spinnerTimeout = setTimeout(() => {
      setIsSubmitting(false);
      setToastMessage(
        "The request is taking longer than expected. Please try again later."
      );
      setShowToast(true);

      // Automatically hide the toast after 5 seconds
      setTimeout(() => setShowToast(false), 5000);
    }, 10000); // 10 seconds timeout

    try {
      const response = await fetch(`${apiUrl}/api_scv/send_email.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok) {
        clearTimeout(spinnerTimeout); // Clear the timeout if the request finishes successfully
        setToastMessage(result.message || "Form submitted successfully.");
      } else {
        clearTimeout(spinnerTimeout);
        setToastMessage(
          result.message || "Failed to send the form. Please try again."
        );
      }
    } catch (error) {
      clearTimeout(spinnerTimeout);
      console.error("Error submitting form:", error);
      setToastMessage("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false); // Hide spinner
      setShowToast(true); // Show toast

      // Automatically hide the toast after 5 seconds
      setTimeout(() => setShowToast(false), 5000);
    }
  };

  return (
    <div>
      {/*  Discount Bar Announcement  */}
      <div class="mx-auto max-w-5xl px-2 mt-4 sm:px-6 lg:px-8">
        <div class="rounded bg-blue-800 p-2 shadow-lg sm:p-3">
          <div class="flex flex-wrap items-center justify-between">
            <div class="flex w-0 flex-1 items-center">
              <p class="ml-3 truncate font-medium text-white">
                <span class="md:hidden">
                  <i class="bi bi-tag"></i> Get 30% discount. T&C applied.
                </span>
                <span class="hidden md:inline">
                  <i class="bi bi-tag text-yellow-400"></i> Get 30% discount
                  while you book any of our services today. T&C Applied
                </span>
              </p>
            </div>
            <div class="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto flex justify-end">
  <button
    onClick={handleScrollToForm}
    className="flex items-center justify-center rounded bg-yellow-400 px-4 py-2 text-sm font-medium text-blue-900 shadow-sm hover:bg-yellow-500"
  >
    Book Now
  </button>
</div>

            <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <button
                type="button"
                class="-mr-1 flex rounded p-2 focus:outline-none focus:ring-2 focus:ring-white hover:bg-blue-600"
              >
                <svg
                  class="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*  Hero Section  */}

      <div class="mx-auto h-full px-4 py-10 sm:max-w-xl md:max-w-full md:px-24 md:py-10 lg:max-w-screen-xl lg:px-8">
        <div class="flex flex-col items-center justify-between lg:flex-row">
          <div class="">
            <div class="lg:max-w-xl lg:pr-5">
              <h2 class="mb-6 max-w-lg text-7xl font-light leading-snug tracking-tight text-blue-600 sm:text-8xl">
                We tailor
                <br />
                your
                <span class="relative my-1 px-4 inline-block border-b-8 border-yellow-400 font-bold text-blue-600">
                  <span class="absolute top-0 right-0 -translate-x-1/2 -translate-y-1/2 bg-purple-800 text-gray-100 font-medium px-2 py-1 text-xl rounded">
                    Web
                  </span>
                  Apps
                </span>
              </h2>
            </div>

            <div class="mt-10 flex flex-col items-start md:flex-row">
              <button
                onClick={handleOpen}
                className="px-4 py-2 inline-flex bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-2 h-6 w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                    clip-rule="evenodd"
                  />
                </svg>
                Watch our project
              </button>
            </div>

            {/* Video Popup Modal */}
            {isOpen && (
              <div
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                onClick={handleClose} // Close modal when clicking outside
              >
                <div
                  className="bg-white rounded-lg shadow-lg max-w-4xl w-full mx-4 relative"
                  onClick={(e) => e.stopPropagation()} // Prevent modal close on inside click
                >
                  {/* Close Button */}
                  <button
                    className="absolute top-3 right-3 text-gray-600 hover:text-gray-800 focus:outline-none z-50"
                    onClick={handleClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>

                  {/* Video Content */}
                  <div className="aspect-w-16 aspect-h-9 w-full">
                    <video controls className="w-full h-full">
                      <source src="/video/SCVByte_vid.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            )}
            <div class="mt-12 flex flex-col space-y-3 divide-gray-300 text-sm text-gray-700 sm:flex-row sm:space-y-0 sm:divide-x">
              <div class="flex max-w-xs space-x-2 px-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-12 w-12 text-emerald-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                  />
                </svg>
                <p> More than 20+ modules have been delivered to our client </p>
              </div>
              <div class="flex max-w-xs space-x-2 px-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-12 w-12 text-orange-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 17l6-6 4 4 6-8M5 19h14M3 3v16"
                  />
                </svg>

                <p>
                  With sleek, modern design web apps you can attract more
                  traffic and increase sales target
                </p>
              </div>
            </div>
          </div>

          {/*  Right Side Promotional Image  */}

          <div class="relative w-full mt-2 lg:ml-32 lg:block lg:w-3/4 lg:h-full">
            <div class="flex h-full w-full space-x-3 overflow-hidden md:justify-end">
              <div class="hidden w-56 items-center space-y-3 lg:flex">
                <div class="overflow-hidden rounded-xl bg-yellow-400">
                  <img
                    class="h-full w-full object-cover"
                    src="/images/phone-mockup-2.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="w-full flex-col space-y-3 rounded-xl py-4 lg:flex lg:w-80">
                <div class="h-40 overflow-hidden border border-gray-200 rounded-xl bg-green-600/60">
                  <img
                    class="mx-auto h-full w-full object-cover"
                    src="/images/hero-6.png"
                    alt=""
                  />
                </div>
                <div class="h-40 overflow-hidden border border-gray-200 rounded-xl bg-pink-600/60">
                  <img
                    class="mx-auto h-full w-full object-cover"
                    src="/images/hero-4.png"
                    alt=""
                  />
                </div>
                <div class="h-40 overflow-hidden border border-gray-200 rounded-xl bg-blue-600/60">
                  <img
                    class="mx-auto h-full w-full object-cover"
                    src="/images/hero-2.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  Achievements Section  */}

        <section class="py-10 leading-6 text-blue-900 sm:py-16 lg:py-24">
          <div class="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
            <div class="mx-auto max-w-xl text-center">
              <h2 class="text-3xl font-bold leading-9 sm:text-4xl sm:leading-tight">
                Achievements in 2 Years
              </h2>
            </div>

            <div class="mt-8 grid grid-cols-1 gap-6 divide-blue-200 sm:grid-cols-2 md:gap-8 lg:mt-16 lg:grid-cols-4 lg:divide-x">
              <div class="border-b border-blue-200 py-10 px-6 lg:border-b-0">
                <div class="flex items-center">
                  <h3 class="relative ml-2 inline-block text-4xl font-bold leading-none">
                    <span class="absolute -top-4 h-2 w-full bg-blue-200"></span>
                    7
                  </h3>
                  <span class="ml-3 text-base font-medium capitalize">
                    total clients SCV Byte served
                  </span>
                </div>
              </div>

              <div class="border-b border-blue-200 py-10 px-6 lg:border-b-0">
                <div class="flex items-center">
                  <h3 class="relative ml-2 inline-block text-4xl font-bold leading-none">
                    <span class="absolute -top-4 h-2 w-full bg-blue-200"></span>
                    20+
                  </h3>
                  <span class="ml-3 text-base font-medium capitalize">
                    Total modules
                  </span>
                </div>
              </div>

              <div class="border-b border-blue-200 py-10 px-6 lg:border-b-0">
                <div class="flex items-center">
                  <h3 class="relative ml-2 inline-block text-4xl font-bold leading-none">
                    <span class="absolute -top-4 h-2 w-full bg-blue-200"></span>
                    15+
                  </h3>
                  <span class="ml-3 text-base font-medium capitalize">
                    jobs delivered
                  </span>
                </div>
              </div>

              <div class="border-b border-blue-200 py-10 px-6 lg:border-b-0">
                <div class="flex items-center">
                  <h3 class="relative ml-2 inline-block text-4xl font-bold leading-none">
                    <span class="absolute -top-4 h-2 w-full bg-blue-200"></span>
                    99%
                  </h3>
                  <span class="ml-3 text-base font-medium capitalize">
                    Happy Customers
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*  Phone Mock  */}

        <div
          className="container mx-auto px-4 py-8"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="flex flex-col lg:flex-row items-center lg:justify-between">
            {/* Left Column */}
            <div className="lg:w-1/3 space-y-8">
              <div
                className="feature-item text-right"
                data-aos="fade-right"
                data-aos-delay="200"
              >
                <div className="flex items-center justify-end gap-4">
                  <div className="feature-content">
                    <h3 className="text-lg font-semibold">Use On Any Device</h3>
                    <p className="text-gray-600">
                      Access seamlessly across all devices, ensuring optimal
                      performance on desktops, tablets, and smartphones.
                    </p>
                  </div>
                  <div className="feature-icon text-2xl text-gray-800">
                    <i className="bi bi-display text-blue-400"></i>
                  </div>
                </div>
              </div>
              <div
                className="feature-item text-right"
                data-aos="fade-right"
                data-aos-delay="300"
              >
                <div className="flex items-center justify-end gap-4">
                  <div className="feature-content">
                    <h3 className="text-lg font-semibold">Feather Icons</h3>
                    <p className="text-gray-600">
                      Lightweight and scalable icons for a clean and modern
                      design.
                    </p>
                  </div>
                  <div className="feature-icon text-xl text-gray-800">
                    <i className="bi bi-feather text-blue-400"></i>
                  </div>
                </div>
              </div>
              <div
                className="feature-item text-right"
                data-aos="fade-right"
                data-aos-delay="400"
              >
                <div className="flex items-center justify-end gap-4">
                  <div className="feature-content">
                    <h3 className="text-lg font-semibold">Retina Ready</h3>
                    <p className="text-gray-600">
                      Crisp and clear visuals optimized for high-resolution
                      displays.
                    </p>
                  </div>
                  <div className="feature-icon text-2xl text-gray-800">
                    <i className="bi bi-eye text-blue-400"></i>
                  </div>
                </div>
              </div>
            </div>

            {/* Middle Column */}
            <div
              className="lg:w-1/3 my-8 lg:my-0 text-center"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              {/* First Phone Mockup */}
              <div className="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px]">
                <div className="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[72px] rounded-s-lg"></div>
                <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                <div className="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                <div className="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white dark:bg-gray-800">
                  <img
                    src="/images/phone-mockup-3.png"
                    className="dark:hidden w-[272px] h-[572px]"
                    alt=""
                  />
                  <img
                    src="/images/phone-mockup-3.png"
                    className="hidden dark:block w-[272px] h-[572px]"
                    alt=""
                  />
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="lg:w-1/3 space-y-8">
              <div
                className="feature-item"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <div className="flex items-center gap-4">
                  <div className="feature-icon text-2xl text-gray-800">
                    <i className="bi bi-code-square text-blue-400"></i>
                  </div>
                  <div className="feature-content">
                    <h3 className="text-lg font-semibold">W3c Valid Code</h3>
                    <p className="text-gray-600">
                      Ensures clean, standard-compliant, and error-free code for
                      better performance and compatibility.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="feature-item"
                data-aos="fade-left"
                data-aos-delay="300"
              >
                <div className="flex items-center gap-4">
                  <div className="feature-icon text-2xl text-gray-800">
                    <i className="bi bi-phone text-blue-400"></i>
                  </div>
                  <div className="feature-content">
                    <h3 className="text-lg font-semibold">Fully Responsive</h3>
                    <p className="text-gray-600">
                      Adapts seamlessly to any screen size for an optimal user
                      experience.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="feature-item"
                data-aos="fade-left"
                data-aos-delay="400"
              >
                <div className="flex items-center gap-4">
                  <div className="feature-icon text-2xl text-gray-800">
                    <i className="bi bi-browser-chrome text-blue-400"></i>
                  </div>
                  <div className="feature-content">
                    <h3 className="text-lg font-semibold">
                      Browser Compatibility
                    </h3>
                    <p className="text-gray-600">
                      Works flawlessly across all major web browsers for
                      consistent performance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Project Portfolio */}

        <section class="mx-auto max-w-screen-xl py-12 text-blue-700 sm:py-16 lg:py-20">
          <div class="mx-auto px-4 sm:px-6 lg:px-8">
            <div class="mx-auto max-w-2xl text-center">
              <p class="text-xl font-medium text-blue-800">INTRODUCING</p>
              <h2 class="mt-2 text-3xl font-bold sm:text-4xl xl:text-5xl">
                Our <span class="bg-yellow-300">Services</span>
              </h2>
              <hr class="mx-auto mt-4 h-2 w-32 border-none bg-blue-700" />
            </div>

            <div class="mt-10 grid grid-cols-1 gap-20 text-center sm:mx-auto sm:max-w-sm md:mt-20 md:max-w-full md:grid-cols-2 md:text-left">
              <div class="">
                <img
                  class="w-full h-56 object-contain md:order-2 md:ml-0 md:object-left"
                  src="/images/project-landingpage.png"
                  alt=""
                />
                <div class="">
                  <h3 class="text-4xl border-l-4 border-gray-500 px-2 mt-2">
                    Sleek and Modern Frontend
                  </h3>
                  <p class="mt-6 text-base text-gray-500">
                    A sleek and modern frontend design emphasizes simplicity,
                    functionality, and an aesthetically pleasing user interface
                    that enhances user experience. This approach focuses on
                    creating an intuitive, responsive, and visually appealing
                    interface that aligns with contemporary design trends and
                    technological standards.
                  </p>
                  <button
                    class="mt-4 rounded-lg bg-blue-700 px-6 py-2 text-white transition hover:translate-y-1"
                    onClick={handleScrollToForm}
                  >
                    Get a quote
                  </button>
                </div>
              </div>

              <div class="">
                <img
                  class="w-full h-56 object-contain md:order-2 md:ml-0 md:object-left"
                  src="/images/project-shoppingcart.png"
                  alt=""
                />
                <div class="">
                  <h3 class="text-4xl border-l-4 border-blue-500 px-2 mt-2">
                    E-commerce
                  </h3>
                  <p class="mt-6 text-base text-gray-500">
                    We provide a user-friendly and secure e-commerce solution
                    with real-time updates, payment gateway integration,
                    responsive design, dynamic pricing, and a wishlist feature
                    to enhance the e-commerce experience and drive customer
                    satisfaction.
                  </p>
                  <button
                    class="mt-4 rounded-lg bg-blue-700 px-6 py-2 text-white transition hover:translate-y-1"
                    onClick={handleScrollToForm}
                  >
                    Get a quote
                  </button>
                </div>
              </div>

              <div class="">
                <img
                  class="w-full h-56 object-contain md:order-2 md:ml-0 md:object-left"
                  src="/images/project-sales.png"
                  alt=""
                />
                <div class="">
                  <h3 class="text-4xl border-l-4 border-green-500 px-2 mt-2">
                    Sales Management System
                  </h3>
                  <p class="mt-6 text-base text-gray-500">
                    We provide a complete Sales Management System to streamline
                    Sales, Operations, Accounts, Admin Controls, Inventory, and
                    User Management. Key features include efficient Receipt
                    Handling and seamless API Integration, ensuring smooth
                    business operations and scalability.
                  </p>
                  <button
                    class="mt-4 rounded-lg bg-blue-700 px-6 py-2 text-white transition hover:translate-y-1"
                    onClick={handleScrollToForm}
                  >
                    Get a quote
                  </button>
                </div>
              </div>

              <div class="">
                <img
                  class="w-full h-56 object-contain md:order-2 md:ml-0 md:object-left"
                  src="/images/project-courier.png"
                  alt=""
                />
                <div class="">
                  <h3 class="text-4xl border-l-4 border-yellow-300 px-2 mt-2">
                    {" "}
                    API Courier Integration
                  </h3>
                  <p class="mt-6 text-base text-gray-500">
                    Successfully achieving a stable API connection for shipment
                    integration is crucial for seamless logistics and
                    operational efficiency. By integrating leading courier
                    services such as DHL, Ninjavan, and Poslaju Malaysia,
                    businesses can streamline their shipping processes and
                    provide real-time updates to customers.
                  </p>
                  <button
                    class="mt-4 rounded-lg bg-blue-700 px-6 py-2 text-white transition hover:translate-y-1"
                    onClick={handleScrollToForm}
                  >
                    Get a quote
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div ref={formRef}>
          <section class=" py-12 text-gray-800 sm:py-24">
            <div class="mx-auto flex max-w-md flex-col rounded-lg lg:max-w-screen-xl lg:flex-row">
              <div class="max-w-2xl px-4 lg:pr-24">
                <p class="mb-2 text-blue-600">Have Custom Needs</p>
                <h3 class="mb-5 text-3xl font-semibold">Get Custom Pricing</h3>
                <p class="mb-16 text-lg text-gray-600">
                  We specialize in developing web applications and landing
                  pages, ranging from simple designs to complex systems — all
                  tailored to fit your budget. Whether you're looking for a
                  basic solution or a feature-rich application, we’ll provide
                  the best options at an affordable price.
                </p>
                <div class="mb-5 flex font-medium">
                  <div class="mr-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="h-7 w-7 text-blue-500"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"
                      />
                    </svg>
                  </div>
                  <div class="">
                    <p class="mb-2">Flexible Budget Options</p>
                    <span class="font-normal text-gray-600">
                      We’ll work within your budget to create a solution that
                      meets your needs without compromising quality.
                    </span>
                  </div>
                </div>
                <div class="mb-5 flex font-medium">
                  <div class="mr-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="h-7 w-7 text-blue-500"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                      />
                    </svg>
                  </div>
                  <div class="">
                    <p class="mb-2">Installment Payments</p>
                    <span class="font-normal text-gray-600">
                      Spread your payments over three easy installments, making
                      it even more convenient to start your project.
                    </span>
                  </div>
                </div>
                <div class="mb-5 flex font-medium">
                  <div class="mr-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="h-7 w-7 text-blue-500"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
                      />
                    </svg>
                  </div>
                  <div class="">
                    <p class="mb-2">Student-Friendly Services</p>
                    <span class="font-normal text-gray-600">
                      Are you a student with a simple project requirement? No
                      worries — we’ve got you covered with affordable and
                      efficient solutions.
                    </span>
                  </div>
                </div>
              </div>
              <div class="border border-gray-100 shadow-gray-500/20 mt-8 mb-8 max-w-md bg-gray-50 shadow-sm sm:rounded-lg sm:shadow-lg lg:mt-0">
                <div class="relative border-b border-gray-300 p-4 py-8 sm:px-8">
                  <h3 class="mb-1 inline-block text-3xl font-medium">
                    <span class="mr-4">Get a quote!</span>
                    <span class="inline-block rounded-md bg-blue-100 px-2 py-1 text-sm text-blue-700 sm:inline">
                      Quick Response
                    </span>
                  </h3>
                  <p class="text-gray-600">Contact us for custom use cases</p>
                </div>
                <div class="p-4 sm:p-8">
                  <form onSubmit={handleSubmit}>
                    <input
                      id="name"
                      type="text"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                      className="mt-2 w-full resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none hover:border-blue-500"
                    />

                    <input
                      id="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter your email"
                      required
                      className="peer mt-4 w-full resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none hover:border-blue-500"
                    />
                    {isTouched && !isValidEmail && (
                      <div className="mt-1 text-left text-xs text-rose-600">
                        Email format incorrect. Please complete the email.
                      </div>
                    )}

                    <select
                      id="package"
                      value={formData.package}
                      onChange={handleChange}
                      required
                      className="mt-4 mb-5 w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none hover:border-blue-500"
                    >
                      <option value="" disabled>
                        Select your package
                      </option>
                      <option value="landing_pages">Landing Pages</option>
                      <option value="ecommerce">E-Commerce</option>
                      <option value="sms">Sales Management System</option>
                      <option value="api_courier">
                        API Courier Integration
                      </option>
                      <option value="student_project">Student's Project</option>
                      <option value="custom_system">Custom System</option>
                    </select>

                    <textarea
                      id="about"
                      value={formData.about}
                      onChange={handleChange}
                      placeholder="Provide more details about your chosen package"
                      required
                      className="mb-8 w-full resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none hover:border-blue-500"
                    ></textarea>

                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="w-full rounded-lg border border-blue-700 bg-blue-700 p-3 text-center font-medium text-white outline-none transition focus:ring hover:border-blue-700 hover:bg-blue-600 hover:text-white"
                    >
                      {isSubmitting ? "Sending..." : "Send"}
                    </button>
                  </form>
                  {/* Spinner (shows while submitting) */}
                  {isSubmitting && <Spinner />}

                  {/* Toast (auto popup after submission) */}
                  {showToast && <Toast message={toastMessage} />}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Home;
