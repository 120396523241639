import React, { useEffect, useState } from "react";

const Toast = ({ message, duration = 5000, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Automatically hide the toast after the specified duration
    const timer = setTimeout(() => {
      setIsVisible(false);
      if (onClose) onClose();
    }, duration);

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, [duration, onClose]);

  if (!isVisible) return null; // Don't render if not visible

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      style={{ zIndex: 9999 }}
    >
      <div className="mx-auto max-w-md overflow-hidden rounded-3xl text-gray-700 shadow-md bg-white">
      <div className="h-40 pt-4 sm:h-24 flex items-center justify-center">
  <i
    className="bi bi-check-circle-fill"
    style={{ fontSize: "4rem", color: "#ADD8E6" }} // Adjust fontSize and color for large size and light blue color
  ></i>
</div>
        <div className="flex flex-col items-center bg-white px-4 py-10">
          <h2 className="mb-2 text-3xl font-bold text-rose-500 sm:text-4xl">Thank You!</h2>
          <p className="mb-8 font-medium text-gray-500 text-center">
  {message}
</p>
  
        </div>
      </div>
    </div>
  );
};

export default Toast;
